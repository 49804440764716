const userProperties = [
  'id',
  'name',
  'email',
  'is_admin',
  'phone',
  'city',
  'state',
  'postal_code',
  'created_at',
  'birthyear',
  'savings',
  'zip'
]
const prefix = 'ALG Properties - '

const identifyUser = email => {
  window.heap.identify(email, 'email')
}

export const setUserProperties = user => {
  user.email && identifyUser(user.email)

  let properties = {}
  userProperties.forEach(prop => {
    if (user[prop]) {
      properties[prefix + prop] = user[prop]
    }
  })
  window.heap.addUserProperties(properties)
}
