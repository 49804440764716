import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import Playground from './components/Playground/playground'

const loadable = loader => Loadable({ loader, loading: Loading })

const Loading = props => {
  if (props.pastDelay) {
    return <div>Loading...</div>
  } else {
    return null
  }
}

export const routes = [
  {
    name: 'Playground',
    exact: true,
    path: '/playground',
    component: Playground,
    env: 'development'
  },
  {
    name: 'Home',
    exact: true,
    path: '/',
    component: () => <Redirect to="/is-an-annuity-right-for-you" />
  },
  {
    name: 'Is an Annuity Right For You?',
    exact: false,
    path: '/is-an-annuity-right-for-you',
    component: loadable(() => import('./components/LandingPageDefault/landing_page_default'))
  }
]

export const renderRoute = (
  { name, path, component, env, children = [], unauthed = null, exact = true, admin = false },
  user = null
) => {
  if (env && env !== process.env.NODE_ENV) {
    return null
  }

  if (!user && unauthed) {
    component = unauthed
  }

  if (admin && !user.is_admin) {
    component = () => <Redirect to="/dash/leads" />
  }

  if (children.length) {
    const Component = component

    return (
      <Route
        key={name}
        {...{ exact, path }}
        render={props => (
          <Component {...props}>
            <Switch>{children.map(child => renderRoute(child, user))}</Switch>
          </Component>
        )}
      />
    )
  } else {
    return <Route key={name} {...{ exact, path, component }} />
  }
}
