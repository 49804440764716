import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import { routes, renderRoute } from './routes'
import AuthProvider, { UserContext } from './components/AuthProvider/auth_provider'

import './styles/styles.scss'

import TagManager from 'react-gtm-module'

TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID
})

render(
  <Router>
    <AuthProvider>
      <UserContext.Consumer>
        {({ user }) => <Switch>{routes.map(route => renderRoute(route, user))}</Switch>}
      </UserContext.Consumer>
    </AuthProvider>
  </Router>,
  document.getElementById('root')
)