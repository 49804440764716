import React from 'react'

function Playground() {
  return (
    <div className="Playground">
      <div bp="grid padding container">
        <h1 bp="12">This is a primary header.</h1>
        <div bp="12 6@md">
          <div className="card" bp="full-width">
            <h2>Secondary header used for card titles</h2>
            <p>The standard p text used for most card text.</p>
            <h3>An h3 header, used for sub-sections within cards.</h3>
            <p className="lr-small-text">
              And some small text which is achieved by the 'lr-small-text' class and used for less
              important things or disclaimers
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Playground
