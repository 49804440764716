const backend = async (method, uri, body, { headers } = {}) => {
  headers = {
    Accept: 'application/json',
    ...headers
  }

  const token = localStorage.getItem('token')
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  if (method !== 'get') {
    headers['Content-Type'] = 'application/json'
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}${uri}`, {
    method,
    headers,
    body: JSON.stringify(body)
  })

  return {
    status: response.status,
    body: await response.json()
  }
}

;['get', 'post', 'put', 'delete'].forEach(method => {
  backend[method] = backend.bind(null, method)
})

export default backend
